<template>
  <div>
    <v-container>
      <p class="mb-n3">
        a) Which piece(s) of glassware is the most precise based on significant figures?
      </p>

      <v-radio-group v-model="inputs.question1Value" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsAShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) What value of R<sup>2</sup> indicates the highest level of precision?</p>

      <v-radio-group v-model="inputs.question2Value" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsBShuffled"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineQuiz1S1Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
      },
      optionsA: [
        {
          text: 'Erlenmeyer Flask & Beaker ',
          value: 'answer1',
        },
        {
          text: 'Beaker & Graduated Cylinder',
          value: 'answer2',
        },
        {
          text: 'Graduated Cylinder & Volumetric Flask',
          value: 'answer3',
        },
        {
          text: 'Volumetric Flask',
          value: 'answer4',
        },
        {
          text: 'Beaker',
          value: 'answer5',
        },
        {
          text: 'Erlenmeyer Flask',
          value: 'answer6',
        },
        {
          text: 'Graduated Cylinder',
          value: 'answer7',
        },
        {
          text: 'Erlenmeyer Flask & Volumetric Flask',
          value: 'answer8',
        },
      ],
      optionsB: [
        {
          text: '0.989',
          value: 'answer1',
        },
        {
          text: '0.743',
          value: 'answer2',
        },
        {
          text: '1.023',
          value: 'answer3',
        },
        {
          text: '0.972',
          value: 'answer4',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsAShuffled() {
      return seededShuffle(this.optionsA, this.seed);
    },
    optionsBShuffled() {
      return seededShuffle(this.optionsB, this.seed);
    },
  },
};
</script>
